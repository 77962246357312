<template>
  <v-app>
    <core-app-bar />

    <core-drawer />

    <core-jumbotron v-if="currentRouteName == 'Home'"/>

    <core-view class="pt-5" />

    <core-footer />
                <v-btn :style="$vuetify.breakpoint.mdAndDown ? '' : 'margin-right: 75px; margin-bottom: 50px;'" onclick="location.href='http://blog.feilcounselingandconsulting.com/'" dark x-large color="primary" fixed right bottom>
                  Visit Blog
                </v-btn>
    <!-- <v-toolbar extended>
            <template>
              <v-fab-transition>
              </v-fab-transition>
            </template>
    </v-toolbar> -->
  </v-app>
</template>

<script>
export default {
  metaInfo: {
    titleTemplate: "%s — Feil Counseling and Consulting.",
  },
  computed: {
    currentRouteName() {
      console.log(this.$route.name)
      return this.$route.name;
    },
  },
  components: {
    CoreDrawer: () => import("@/components/core/Drawer"),
    CoreFooter: () => import("@/components/core/Footer"),
    CoreJumbotron: () => import("@/components/core/Jumbotron"),
    CoreAppBar: () => import("@/components/core/AppBar"),
    CoreView: () => import("@/components/core/View"),
  },
};
</script>
<style>
.big-text {
    font-size: 3em;
}
/* .body {
  font-family: "Times New Roman", Helvetica, sans-serif;
} */

  .v-btn--example {
    bottom: 0;
    position: fixed;
  }
</style>
