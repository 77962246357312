
export const routes = [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/Home'),
      meta: {
        sitemap: {
            priority:    1.0,
            changefreq: 'weekly',
        }
      }
    },
    {
      path: '/counseling',
      name: 'Counseling',
      component: () => import('@/views/Counseling'),
      meta: {
        sitemap: {
            priority:    0.8,
            changefreq: 'weekly',
        }
      }
    },
    {
      path: '/inofficeappointment',
      name: 'InofficeAppointment',
      component: () => import('@/views/InOffice'),
      meta: {
        sitemap: {
            priority:    0.5,
            changefreq: 'weekly',
        }
      }
    },
    {
      path: '/marriage',
      name: 'Marriage',
      component: () => import('@/views/Marriage')
    },
    {
      path: '/family',
      name: 'Family',
      component: () => import('@/views/Family')
    },
    {
      path: '/ministry',
      name: 'Ministry',
      component: () => import('@/views/Christian'),
      meta: {
        sitemap: {
            priority:    0.8,
            changefreq: 'weekly',
        }
      }
    },
    {
      path: '/consulting',
      name: 'Consulting',
      component: () => import('@/views/Consulting'),
      meta: {
        sitemap: {
            priority:    0.8,
            changefreq: 'weekly',
        }
      }
    },
    {
      path: '/contact',
      name: 'Contact',
      component: () => import('@/views/Contact'),
      meta: {
        sitemap: {
            priority:    0.5,
            changefreq: 'weekly',
        }
      }
    },
    {
      path: '/about',
      name: 'About',
      component: () => import('@/views/About'),
      meta: {
        sitemap: {
            priority:    0.5,
            changefreq: 'weekly',
        }
      }
    }
  ]
