import Vue from "vue";
import "./plugins/meta";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import Router from "vue-router";
import axios from "axios";
import store from "./store";
import { routes } from "./routes.js";
import VueAnalytics from "vue-analytics";

Vue.use(Router);
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

//and then use it in main.js
Vue.use(VueAnalytics, {
  id: "UA-197093985-1",
  router
});

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

Vue.prototype.$environment =
  process.env.NODE_ENV == "development" ? "development" : "production";
Vue.prototype.$apidomain =
  process.env.NODE_ENV == "development"
    ? `http://localhost:3000`
    : `https://feilcounselingapi-19a9153f6a00.herokuapp.com`;
Vue.prototype.$maindomain =
  process.env.NODE_ENV == "development"
    ? `http://localhost:8080`
    : `https://feilcounselingapi-19a9153f6a00.herokuapp.com`;
// Vue.prototype.$blogdomain = process.env.NODE_ENV == 'development' ? `http://ec2-54-84-17-14.compute-1.amazonaws.com` : `http://ec2-54-84-17-14.compute-1.amazonaws.com`;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
